<template>
  <div class="right_cont">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/account/title_ic10.png" />Alternate Contacts</div>
      <div class="btn_export flex_center" v-show="isEdit" @click="editForm"><img src="../../assets/img/account/edit_ic.png" />Edit</div>
      <div class="btn_export btn_save flex_center" v-show="!isEdit" @click="submitForm"><img src="../../assets/img/account/btn_save.png" />Save</div>
    </div>
    <div class="right_cont_box">
      <el-form ref="settingForm" label-position="left" :hide-required-asterisk="true" :model="settingForm" :rules="settingFormRules" label-width="206px" class="asset_form">
        <el-form-item label="Full Name:" prop="fullName">
          <el-input v-model="settingForm.fullName" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="Phone Number:">
          <el-input v-model="settingForm.phoneNumber" :disabled="isEdit"></el-input>
          <!-- <div v-show="!isEdit" class="phone1">
            <el-tag :key="tag" v-for="tag in phones" closable :disable-transitions="false" @close="handleClose(tag)">
              {{ tag }}
            </el-tag>
            <el-input class="input-new-tag" v-model="phone" @blur="blurPhone"></el-input>
          </div>
          <div v-show="isEdit" class="phone">
            <el-tag :key="index" v-for="(tag, index) in phones">
              {{ tag }}
            </el-tag>
          </div> -->
        </el-form-item>
        <el-form-item label="Email:" prop="email">
          <el-input v-model="settingForm.email" :disabled="isEdit"></el-input>
          <!-- <div v-show="!isEdit" class="phone1">
            <el-tag :key="tag" v-for="tag in emails" closable :disable-transitions="false" @close="handleClose1(tag)">
              {{ tag }}
            </el-tag>
            <el-input class="input-new-tag" v-model="email" ref="saveTagInput" @blur="blurEmail"></el-input>
          </div>
          <div v-show="isEdit" class="phone">
            <el-tag :key="index" v-for="(tag, index) in emails" :disable-transitions="false">
              {{ tag }}
            </el-tag>
          </div> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { updateContact } from '@/api/userApi.js'
export default {
  data() {
    //邮箱校验
    var validateEmail=(rule,value,callback) => {
        if (value !== '') {
            let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if (!reg.test(value)) {
                callback(new Error('Incorrect account format'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    }
    return {
      isEdit: true,
      settingForm: {
        fullName: '',
        phoneNumber: '',
        email: '',
        type:0
      },
      // phones: [],
      // emails: [],
      // phone: '',
      // email:'',
      settingFormRules: {
        fullName: [{ required: true, message: 'Please enter the Full Name', trigger: 'blur' }],
        //phoneNumber: [{ required: true, message: 'Please enter the Phone Number', trigger: 'blur' }],
        email:[{ required: true, validator: validateEmail, trigger: 'blur' }],
      },
    }
  },
  computed: {},
  created() {
    this.setAlter()
  },
  methods: {
    async setAlter() {
     let res= await updateContact(this.settingForm)

     if(res && res.code && res.data){
        this.settingForm= {
        fullName: res.data.fullName,
        phoneNumber: res.data.phoneNumber,
        email: res.data.email,
      }
      // this.phones=res.data.phoneNumber?res.data.phoneNumber:[]
      // this.emails=res.data.email?res.data.email:[]
     }
    },
    //编辑
    editForm() {
      this.isEdit = false
    },
    //提交编辑
    async  submitForm() {
      this.settingForm.type = 1
      // this.settingForm.phoneNumber=this.phones.toString()
      // this.settingForm.email=this.emails.toString()
      // this.phone=''
      // this.email=''
      let res = await updateContact(this.settingForm)
      if (res && res.code) {
        this.$message({
          message: res.msg,
          center: true,
          type: 'success',
        })
        this.isEdit = true
      }
    },
    // blurPhone() {
    //     if(this.phone)this.phones.push(this.phone)
    //     this.phone=''
    // },
    // blurEmail() {
    //     if(this.email)this.emails.push(this.email)
    //     this.email=''
    // },
    // handleClose(tag) {
    //   this.phones.splice(this.phones.indexOf(tag), 1)
    // },
    // handleClose1(tag) {
    //   this.emails.splice(this.emails.indexOf(tag), 1)
    // },
  },
}
</script>
<style scoped lang="less">
.right_cont_box {
  padding: 30px;
}
.right_cont {
  margin-top: 12px;
}
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}

.btn_export {
  margin-right: 0;
  margin-left: auto;
}
.asset_form .el-form-item:not(:last-child) {
  border-bottom: 1px solid #e7eaed;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.asset_form .el-form-item:last-child {
  margin-bottom: 0;
}
.asset_form /deep/ .el-form-item .el-input__inner {
  border-width: 0;
}
.asset_form /deep/ .el-form-item__error {
  padding-top: 0;
}
.asset_form /deep/ .el-form-item__label {
  color: #8497ab;
}
.asset_form /deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #022955;
}
.btn_save {
  background: #e7eaed;
  color: #022955;
}
.phone1 {
  display: flex;
  padding: 0 15px;
  .el-tag {
    // width: 120px;
    height: 40px;
    background: #edf9ffff;
    border: 1px solid #1290c9;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-family: ArialMT;
    color: #022955ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.phone {
  display: flex;
  padding: 0 15px;
  .el-tag {
    // width: 120px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-family: ArialMT;
    color: #022955ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
